.calendar-input {
  position: relative;
  cursor: default;
  
  input {
    font-family: 'Roboto', sans-serif;
    margin-top: 10px;
    height: 50px;
    width: 200px;
    outline: none;
    border: 1px solid rgba(53, 71, 80, 0.5);
    border-radius: 5px;
    color: #354750;
    padding: 0 10px;
    font-size: 15px;
    transition: all 0.3s;

    &::placeholder {
      color: rgba(53, 71, 80, 0.5);
    }

    &:hover, &:active, &:focus {
      border-color: #F6872D;
      transition: all 0.3s;
    }
  }

  .DayPicker-Day {
    padding: 0.5em !important;
  }

  .DayPicker-Day--selected {
    color: #fff !important;
    background: #F6872D !important;
  }
  
  .DayPicker-Month {
    table-layout: fixed;
    width: 200px;
  }

  img {
    position: absolute;
    right: 10px;
    top: 24px;
    opacity: 0.8;
    transition: all 0.2s;
    pointer-events: none;
  }

  &.red-border {
    input {
      border-color: red;
    }
  }

  &:hover {
    img {
      opacity: 1;
      transition: all 0.2s;
    }
  }
}