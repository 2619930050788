.help-section-tab {

  @media screen and (max-width: 768px) {
    background: #fff;
    box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
    border-radius: 5px;
    padding: 16px;
  }
  
  .rich-text-editor {
    min-height: 400px;
  }
  
  .buttons-wrapper {
    margin-top: 30px;
    display: flex;

    .button {
      margin-right: 20px;
    }
  }
}