.days-with-trackings-component {
  background: #fff;
  box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
  border-radius: 20px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px;
  overflow: hidden;
  
  @media screen and (max-width: 768px) {
    height: 140px;
  }
  
  .left {
    flex: 2;
    display: flex;
    flex-direction: column;  
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    
    h2 {
      margin-bottom: 10px;

      @media screen and (max-width: 768px) {
        margin-bottom: 0;
      }
    }
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;  
    justify-content: center;
    align-items: center;

    h3 {
      margin-top: 5px;
    }
  }
}