.projects-component {
  background: #fff;
  box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
  border-radius: 20px;
  height: 180px;
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    height: 140px;
  }

  h2 {
    margin-bottom: 10px;
  }
}