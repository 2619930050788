.date-filter {
  position: relative;
  
  .filter-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    box-shadow: 5px 10px 12px rgba(206,206,206,0.25);
    width: 130px;
    height: 38px;
    color: #000000;
    padding-left: 10px;
    cursor: default;
    user-select: none;
    // transition: background 0.3s;
    
    // &:hover {
    //   background: #fafafa;
    //   transition: background 0.3s;
    // }
    
    img {
      padding: 8px 10px;
      transition: all 0.2s;
      
      &:hover {
        filter: brightness(75%);
        transition: all 0.2s;
      }
    }
  }

  .filter-dropdown {
    position: absolute;
    background: #fff;
    top: 45px;
    width: 370px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 5px 10px 12px rgba(206,206,206,0.25);
    display: flex;
    padding: 4px 0;
    z-index: 1;
    opacity: 0;

    .left {
      border-right: 1px solid #eee;

      .filter-option {
        color: #354750;
        padding: 8px 30px 8px 10px;
        white-space: nowrap;
        cursor: default;

        &:hover {
          background: rgba(246, 135, 45, 0.2);
        }

        &.filter-option-selected {
          background: #F6872D;
          color: #fff;
        }
      }
    }

    .right {
      flex: 1;
      padding: 8px 10px;
      color: #354750;

      .calendar-input-wrapper {
        input {
          margin-top: 10px;
          height: 30px;
          outline: none;
          border: 1px solid #ddd;
          border-radius: 5px;
          color: #354750;
          padding: 0 5px;
          width: 150px;
        }

        .DayPicker-Day {
          padding: 0.5em !important;
        }

        .DayPicker-Day--selected {
          color: #F6872D !important;
          font-weight: 700 !important;
        }

      }

      .button-wrapper {
        margin-top: 10px;

        .button {
          background: #F6872D;
          color: #fff;
          height: 32px;
          transition: background 0.8s;

          &:hover {
            background: rgba(246, 135, 45, 0.2);
            transition: background 0.8s;
          }
        }
      }
    }
  }

  .DayPickerInput {
    input {
      transition: all 0.3s;

      &::placeholder {
        color: rgba(53, 71, 80, 0.5);
      }

      &:hover, &:active, &:focus {
        border-color: #F6872D !important;
        transition: all 0.3s;
      }
    }
  }

  .DayPickerInput-OverlayWrapper {
    @media screen and (max-width: 768px) {
      position: relative;
      left: -40px;
    }
  }
}