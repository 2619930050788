.switch {
  display: inline-block;
  height: 20px;

  .switch-background {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background: #E3E3E3;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: #ddd;
    }

    &.switch-background-blue {
      background: #F6872D;
      transition: all 0.3s;

      &:hover {
        background: rgba(246, 135, 45, 0.8);
      }
    }

    .switch-indicator {
      position: absolute;
      display: inline-block;
      left: 3px;
      top: 3px;
      width: 14px;
      height: 14px;
      background: #fff;
      border-radius: 50%;
      transition: left 0.3s;

      &.switch-indicator-active {
        left: 23px;
        transition: left 0.3s;
      }
    }
  }
}