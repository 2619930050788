.tracking-by-countries-component {
  background: #fff;
  box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
  border-radius: 20px;
  padding: 30px;
  height: 100%;

  .empty-image {
    padding: 20px 0;
    width: 100%;
  }

  .country-bar-wrapper {
    padding: 20px 0;
  }

  .all-countries-button {
    font-size: 14px;
    line-height: 16px;
    color: #F6872D;
    cursor: pointer;

    &.all-countries-button-inactive {
      cursor: default;
      color: rgba(246, 135, 45, 0.2);
    }
  }
}