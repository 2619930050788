.drag-and-drop-file-upload {
  position: relative;
  width: 250px;
  height: 100px;
  border: 1px solid #F6872D;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-left: 5px;
  }

  .drop-active-overlay {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 25px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #354750;
  }

  .browse-button {
    font-weight: 500;
    cursor: pointer;
    font-size: 15px;
    color: #354750;
    border: 1px solid #F6872D;
    border-radius: 5px;
    padding: 4px;

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    label {
      cursor: pointer;
    }
  }
}