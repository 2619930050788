.main-component {
  position: relative;
  display: flex;
  background: #FAFCFE;

  .page {
    flex: 1;
    min-height: 100vh;
    margin-left: 255px;

    @media screen and (max-width: 992px) {
      margin-left: 0;
    }
  }
}