.countries-tab {
  max-width: 720px;
  
  .search-line {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
      input {
        height: 40px;
        background: transparent;
      }
    }
  }

  .table-wrapper {
    @media screen and (max-width: 768px) {
      display: none;
    }

    .table-headers {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  
    .table-row {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 2fr;
      transition: background 0.3s;
  
      &:hover {
        background: #F3F3F3;
        transition: background 0.3s;
      }
  
      .table-cell {
        padding: 10px 0;
        min-height: 50px;
        display: flex;
        align-items: center;
      }
    }
  }

  .data-list-mobile {

    .list-item {
      background: #fff;
      box-shadow: 5px 10px 12px rgba(206, 206, 206, 0.25);
      border-radius: 5px;
      margin-top: 20px;
      padding: 24px 12px;

      .list-item-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        .item-cell {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }

  .text {
    @media screen and (max-width: 1400px) and (min-width: 769px) {
      font-size: 13px;
    }
  }
}