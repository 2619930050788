.all-trackings-component {
  background: #fff;
  box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
  border-radius: 20px;
  padding: 30px;

  header {
    display: flex;
    justify-content: space-between;
    margin: 0;
  }

  .table-headers {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 1fr;

    @media screen and (max-width: 1400px) {
      grid-template-columns: 4fr 4fr 4fr 4fr 4fr 4fr 3fr;
    }
  }

  .table-row {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 1fr;
    transition: background 0.3s;

    @media screen and (max-width: 1400px) {
      grid-template-columns: 4fr 4fr 4fr 4fr 4fr 4fr 3fr;
    }

    &:hover {
      background: #F3F3F3;
      transition: background 0.3s;

      .edit-row-icon {
        display: inline-block !important;
      }
    }

    .table-cell {
      padding: 15px 10px 15px 0;
      min-width: 0;
      
      &.centered {
        display: flex;
        align-items: center;
      }
      
      &.last-cell {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .edit-row-icon {
          display: none;
          margin-right: 20px;
          cursor: pointer;
        }

        .save-row-icon {
          margin-right: 10px;
          cursor: pointer;
        }

        .cancel-row-icon {
          margin-right: 20px;
          cursor: pointer;
        }
        
        @media screen and (max-width: 1400px) {
          .edit-row-icon {
            margin-right: 10px;
          }
          .save-row-icon {
            margin-right: 10px;
          }
          .cancel-row-icon {
            margin-right: 10px;
          }
        }
      }

      .label-working {
        padding: 8px 20px;
        background: rgba(3, 117, 180, 1);
        color: #fff;
        border-radius: 5px;

        @media screen and (max-width: 1400px) and (min-width: 769px) {
          padding: 8px;
        }
      }

      .label-non-working {
        padding: 8px 20px;
        background: rgba(3, 117, 180, 0.5);
        color: #fff;
        border-radius: 5px;
        white-space: nowrap;

        @media screen and (max-width: 1400px) and (min-width: 769px) {
          padding: 8px;
        }
      }

      .calendar-input {
        width: 100%;

        .DayPickerInput {
          width: 100%;
        }

        input {
          margin-top: 0;
          width: 100%;
          height: 38px;
        }
        img {
          top: 8px;
        }
      }
    }
      
    &.small-vertical-padding {
      .table-cell {
        padding: 5px 10px 5px 0;
      }
    }
  }

  .table-data {
    margin-top: 30px;
    display: flex;
    align-items: center;

    & > div {
      flex: 1;
    }
    
    .button {
      flex: 0 0 120px;
      height: 35px;
    }
  }

  .text {
    @media screen and (max-width: 1400px) and (min-width: 769px) {
      font-size: 13px;
    }
  }
}