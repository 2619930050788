.settings-page {
  padding: 25px 20px 50px;

  @media screen and (max-width: 768px) {
    padding: 16px 16px 32px;
  }
  
  header.header-desktop {
    margin-bottom: 40px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  header.header-mobile {
    display: none;

    .title-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 55px;

      .dropdown-wrapper {

      }
    }

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .tabs-wrapper-desktop {
    background: #fff;
    box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
    border-radius: 20px;
    padding: 30px;

    @media screen and (max-width: 768px) {
      display: none;
    }

    .tabs-list {
      border-bottom: 1px solid rgba(53, 71, 80, 0.5);
      display: flex;

      .tab {
        width: 200px;
        padding-bottom: 10px;
        cursor: pointer;
        transition: color 0.3s;

        @media screen and (max-width: 1400px) {
          width: auto;
          flex: 1;
        }

        &:hover {
          color: rgba(246, 135, 45, 0.8);
          transition: color 0.3s;
        }
      }
    }

    .selected-tab {
      margin-top: 30px;
    }
  }

  .tabs-wrapper-mobile {
    display: none;
    margin-top: 20px;
    

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

}