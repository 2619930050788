.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100vw;
  height: 100vh;

  .login-box {

    .header-line {
      position: relative;
      margin-bottom: 70px;

      img {
        position: absolute;
        right: -50px;
        top: -25px;
        width: 61px;
      }
    }

    
    .input-wrapper {
      margin-bottom: 20px;

      input {
        height: 44px;
        border-radius: 0;
        font-size: 14px;

        &::placeholder {
          color: rgba(53, 71, 80, 0.5);
        }
      }
    }

    .buttons-wrapper {
      margin-top: 50px;
      
      .button {
        width: 100%;
        border-radius: 0;
        height: 44px;
      }
    }
  }
}