.help-page {
  padding: 25px 20px 50px;

  @media screen and (max-width: 768px) {
    padding: 16px 16px 32px;
  }

  header {
    margin-bottom: 40px;

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  
  .help-content {
    background: #fff;
    box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
    border-radius: 20px;
    padding: 20px 20px 50px;

    @media screen and (max-width: 768px) {
      border-radius: 5px;
    }

    h2 {
      margin-bottom: 10px;
      font-size: 24px;
      line-height: 28px;
    }

    h3 {
      margin-bottom: 10px;
      font-size: 20px;
      line-height: 24px;
    }

    p {
      margin-bottom: 10px;
    }

    ul {
      padding-left: 20px;
    }
  }
}