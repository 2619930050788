.popover-menu-component {

  .icon-wrapper {
    position: relative;

    img {
      padding: 4px 0;
      cursor: pointer;
    }
  }

  .popover-menu {
    position: absolute;
    left: -62px;
    z-index: 20;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 140px;
    padding: 8px 0;
    box-shadow: 0px 1px 4px rgba(206, 206, 206, 0.2);

    &.popover-menu-hidden {
      visibility: hidden;
    }

    &.menu-bottom {
      top: 21px;
    }

    &.menu-top {
      bottom: 24px;
    }

    .menu-border-triangle {
      display: block;
      position: absolute;
      left: 63px;
      background: #fff;
      width: 12px;
      height: 12px;
      transform: rotate(45deg);

      &.top-triangle {
        top: -7px;
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
      }

      &.bottom-triangle {
        bottom: -7px;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
      }
    }

    .popover-menu-option {
      cursor: pointer;
      padding: 10px 10px;
      
      &:hover {
        background: #eee;
      }
    }
  }
}

      