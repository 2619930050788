.side-menu {
  position: fixed;
  width: 255px;
  height: 100%;
  background: #fff;
  border-radius: 0 20px 20px 0;
  box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0;

  .logo-wrapper {
    display: inline-block;
    margin-bottom: 50px;
    margin-left: 50px;

    img {
      height: 30px;
    }
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
}


.hamburger-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;

  @media screen and (max-width: 992px) {
    display: block;
  }

  .hamburger-menu-icon {
    position: absolute;
    top: 0px;
    right: -100vw;
    padding: 25px;
    cursor: pointer;
    z-index: 20;
    height: 65px;

    &.icon-hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .close-menu-icon {
    position: fixed;
    top: 0;
    right: 0;
    padding: 25px;
    cursor: pointer;
    z-index: 20;
    height: 65px;
  }

  .mobile-menu-overlay {
    background: #c4c4c4;
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;

    &.overlay-visible {
      opacity: 0.7;
      pointer-events: auto;
      transition: opacity 0.2s;
    }
  }

  .mobile-menu {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 255px;
    transition: left 0.2s;
    height: 100%;
    z-index: 20;
    box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
    border-radius: 0 20px 20px 0;
    padding: 16px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      margin-left: 16px;
    }

    &.hidden {
      left: -255px;
      transition: left 0.2s;
    }
  }
}


.side-menu-link {
  display: flex;
  align-items: center;
  height: 38px;
  margin: 10px 0;
  color: rgba(53, 71, 80, 0.5);
  transition: color 0.3s;

  &:hover {
    color: #F6872D;
    transition: color 0.3s;
  }

  .link-indicator {
    display: inline-block;
    width: 4px;
    height: 38px;
  }

  .link-icon {
    margin-left: 46px;
    margin-right: 16px;

    @media screen and (max-width: 992px) {
      margin-left: 18px;
    }
  }

  &.link-selected {
    .link-indicator {
      background: #F6872D;
      border-radius: 0 4px 4px 0;
    }

    .link-icon {
      filter: invert(10%) sepia(111%) saturate(5000%) hue-rotate(10deg) brightness(200%) contrast(100%)
    } 
    
    .link-text {
      color: #F6872D;
    }
  }
}