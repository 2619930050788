.dashboard-page {
  padding: 25px 20px 50px;

  @media screen and (max-width: 768px) {
    padding: 16px 16px 32px;
  }

  header.header-desktop {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;

    @media screen and (max-width: 1400px) {
      display: block;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }

    h1 {
      @media screen and (max-width: 1400px) {
        margin-bottom: 20px;
      }
    }

    .filters-wrapper {
      display: flex;
      align-items: center;
      background: transparent;

      @media screen and (max-width: 1400px) {
        flex-wrap: wrap;
      }

      & > div {
        margin-left: 15px;

        @media screen and (max-width: 1400px) {
          margin-left: 0;
          margin-right: 15px;
          margin-bottom: 10px;
        }
      }
      
      .label {
        min-width: 60px;
        text-align: right;

        @media screen and (max-width: 1400px) {
          min-width: fit-content;
          margin-left: 0;
        }
      }
    }
  }

  .desktop-filters-sticky {
    position: fixed;
    left: 265px;
    right: 10px;
    top: 0;
    background: #fff;
    z-index: 1;
    margin-top: 0px;
    padding: 10px 10px 0px;
    border-radius: 0 0 15px 15px;
    box-shadow: 5px 10px 12px rgba(206, 206, 206, 0.25);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    opacity: 1;
    pointer-events: initial;

    @media screen and (max-width: 768px) {
      display: none;
    }

    @media screen and (max-width: 992px) {
      left: 10px;
    }

    @media screen and (max-width: 1400px) {
      justify-content: flex-start;
    }

    &.invisible {
      opacity: 0;
      pointer-events: none;
    }

    .label {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }

    .filters-list {
      display: flex;
      flex-wrap: wrap;

      & > div {
        margin-left: 15px;
        margin-bottom: 10px;

        @media screen and (max-width: 1400px) {
          margin-left: 0;
          margin-right: 15px;
        }
      }
    }
  }

  header.header-mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }

    .filters-wrapper-mobile {
      margin-top: 20px;

      &.filter-mobile-fixed {
        position: fixed;
        left: 0;
        top: 0;
        background: #fff;
        z-index: 1;
        margin-top: 0px;
        padding-top: 10px;
        padding-left: 16px;
        width: 100%;
        border-radius: 0 0 15px 15px;
        box-shadow: 5px 10px 12px rgba(206, 206, 206, 0.25);

        .label {
          display: none;
        }
      }

      .label {
        margin-bottom: 10px;
      }

      .filters-list {
        display: flex;
        flex-wrap: wrap;

        & > div {
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }

    .mobile-filters-sticky {
      position: fixed;
      left: 0;
      top: 0;
      background: #fff;
      z-index: 1;
      margin-top: 0px;
      padding-top: 10px;
      padding-left: 16px;
      width: 100%;
      border-radius: 0 0 15px 15px;
      box-shadow: 5px 10px 12px rgba(206, 206, 206, 0.25);
      opacity: 1;
      pointer-events: initial;

      &.invisible {
        opacity: 0;
        pointer-events: none;
      }

      .filters-list {
        display: flex;
        flex-wrap: wrap;

        & > div {
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .stats-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 30px 20px;

    &.stats-grid-container-user {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media screen and (max-width: 1400px) {
      grid-template-columns: 1fr 1fr;
      gap: 16px 16px;
      margin-top: 20px;
    }
  }

  .grid-container {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 
      "countries-tracking countries-tracking quick-log quick-log"
      "all-trackings all-trackings all-trackings all-trackings";
    gap: 30px 20px;

    @media screen and (max-width: 1400px) {
      margin-top: 16px;
      grid-template-areas: 
        "quick-log quick-log quick-log quick-log"
        "countries-tracking countries-tracking countries-tracking countries-tracking"
        "all-trackings all-trackings all-trackings all-trackings";
      gap: 16px 16px;
    }

    @media screen and (max-width: 768px) {
      grid-template-areas: 
        "countries-tracking countries-tracking countries-tracking countries-tracking"
        "quick-log quick-log quick-log quick-log";
      gap: 16px 16px;
    }

    .working-days { grid-area: working-days; }
    .not-working-days { grid-area: not-working-days; }
    .countries { grid-area: countries; }
    .projects { grid-area: projects; }
    .countries-tracking { grid-area: countries-tracking; }
    .quick-log { grid-area: quick-log; }
    .all-trackings { grid-area: all-trackings; }
  }

  .add-tracking-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background: #F6872D;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    user-select: none;

    .button-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}