@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.App {
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  outline: none;
  border: none;
}

a {
  text-decoration: initial;
  color: initial;
}

h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 56px;
  color: #354750;
}

h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;
  color: #354750;
}

h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #354750;
}

h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: rgba(53, 71, 80, 0.5);
}

p, ul, li {
  color: #354750;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #354750;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #354750;
}

.help-text {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: rgba(53, 71, 80, 0.5);
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border-radius: 5px;
  padding: 0 20px;
  width: fit-content;
  min-width: 100px;
  height: 50px;
  cursor: pointer;
  user-select: none;


  &.button-primary {
    background: #F6872D;
    background-position: center;
    color: #fff;
    transition: background 0.3s;

    &:hover {
      background: rgba(246, 135, 45, 0.8);
      transition: background 0.3s;
    }
  }

  &.button-primary-disabled {
    background: rgba(246, 135, 45, 0.3);
    border-radius: 5px;
    pointer-events: none;
  }

  &.button-secondary {
    background: #fff;
    color: #354750;
    border: 1px solid #F6872D;
    transition: background 0.3s;

    &:hover {
      background: #f0f0f0;
      transition: background 0.3s;
    }
  }
}

.search-bar {
  width: 166px;
  height: 25px;
  padding: 0 12px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgba(53, 71, 80, 0.5);
  color: #354750;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  transition: border 0.3s;

  &::placeholder {
    color: rgba(53, 71, 80, 0.5);
  }

  &:hover, &:active, &:focus {
    border: 1px solid #F6872D;
    transition: border 0.3s;
  }
}

.text-input {
  height: 50px;
  border: 1px solid rgba(53, 71, 80, 0.5);
  border-radius: 5px;
  outline: none;
  padding: 0 10px;
  color: #354750;
  font-size: 15px;
  transition: all 0.3s;

  &::placeholder {
    color: rgba(53, 71, 80, 0.5);
  }

  &:hover, &:active, &:focus {
    border-color: #F6872D;
    transition: all 0.3s;
  }
}

.bold {
  font-weight: bold;
}
  
.w-200 {
  width: 200px;
}

.w-250 {
  width: 250px;
}

.border-red {
  border-color: #FF595E;
}

.color-red {
  color: #FF595E;
}

.color-primary-default { color: #F6872D; }
.color-primary-light { color: rgba(246, 135, 45, 0.5); }
.color-primary-lightest { color: rgba(246, 135, 45, 0.2); }

.color-secondary-default { color: #008B69; }
.color-secondary-light { color: rgba(0, 139, 105, 0.5); }
.color-secondary-lightest { color: rgba(0, 139, 105, 0.2); }

.color-workingday-default { color: #0375B4; }
.color-workingday-light { color: rgba(3, 117, 180, 0.5); }
.color-workingday-lightest { color: rgba(3, 117, 180, 0.2); }

.color-nonworkingday-default { color: rgba(3, 117, 180, 0.5); }
.color-nonworkingday-light { color: rgba(3, 117, 180, 0.5); }
.color-nonworkingday-lightest { color: rgba(3, 117, 180, 0.2); }

.color-state-open-default { color: #ABC270; }
.color-state-open-light { color: rgba(171, 194, 112, 0.5); }
.color-state-open-lightest { color: rgba(171, 194, 112, 0.2); }

.color-text-default { color: #354750; }
.color-text-light { color: rgba(53, 71, 80, 0.5); }
.color-text-lightest { color: rgba(53, 71, 80, 0.2); }


.hide-small-screens {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.show-small-screens {
  display: none;
  @media screen and (max-width: 768px) {
    display: initial;
  }
}


.ripple-effect {
  position: relative;
  overflow: hidden;
}

.ripple-effect-circle {
  border-radius: 50%;
  background: transparent;
}

.ripple-effect::after {
  display: none;
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  top: 50%;
  left: 50%;
  animation: ripple 1s;
  opacity: 0;
}

.ripple-effect-lighter::after {
  background-color: rgba(255, 255, 255, 0.1);
}

.ripple-effect:focus:not(:active)::after {
  display: block;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(10);
  }
}