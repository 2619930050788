.projects-page {
  padding: 25px 20px 50px;

  @media screen and (max-width: 768px) {
    padding: 16px 16px 32px;
  }

  header.header-desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    @media screen and (max-width: 768px) {
      display: none;
    }

    @media screen and (max-width: 992px) {
      margin-right: 30px;
    }

    .button {
      margin-left: 30px;
      height: 40px;

      img {
        margin-right: 5px;
        margin-top: 0;
      }
    }
  }

  header.header-mobile {
    display: none;

    .title-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 40px;

      .search-bar {
        border-color: #F6872D;
        height: 40px;
      }
    }

    .switch-wrapper {
      margin-top: 20px;
      display: flex;
      align-items: center;

      .label {
        margin-right: 10px;
        margin-top: 0;
      }
    }

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .content-wrapper {
    background: #fff;
    box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
    border-radius: 20px;
    padding: 30px;
    max-width: 900px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      display: none;
    }

    header {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title-right-area {
        display: flex;
        align-items: center;
  
        .switch-wrapper {
          margin-right: 30px;
          display: flex;
          align-items: center;
  
          .label {
            margin-right: 10px;
            margin-top: 0;
          }
        }
      }
    }

    .table-headers {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
    }

    .table-row {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
      transition: background 0.3s;
  
      &:hover {
        background: #F3F3F3;
        transition: background 0.3s;
      }
  
      .table-cell {
        padding: 15px 10px 15px 0;
        min-width: 0; 

        .label {
          @media screen and (max-width: 1400px) and (min-width: 769px) {
            word-wrap: break-word;
          }
        }

        .supervisor-name-line {
          width: 100%;
          padding-right: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            cursor: pointer;
            filter: brightness(0) saturate(100%);
            padding: 0;
          }
        }

        .additional-supervisor {
          margin-top: 10px;
        }
  
        img {
          padding: 4px 0;
          cursor: pointer;
        }
      }
    }

    

    .table-grid {
      margin: 30px 0;
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 2fr 1fr;

      .grid-cell {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        &.cell-top-aligned {
          justify-content: flex-start;
        }
        &.padding-20 {
          padding-top: 20px;
        }
        &.padding-12 {
          padding-top: 12px;
        }

        .supervisor-name-line {
          width: 100%;
          padding-right: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            cursor: pointer;
            filter: brightness(0) saturate(100%);
            padding: 0;
          }
        }

        .additional-supervisor {
          margin-top: 10px;
        }
  
        img {
          padding: 4px 0;
          cursor: pointer;
        }
      }
    }

    .table-data {
      margin-top: 30px;
      display: flex;
      align-items: center;
  
      & > div {
        flex: 1;
      }
      
      .button {
        flex: 0 0 120px;
        height: 35px;
      }
    }
  }
  
  .label {
    margin-top: 3px;
  }

  .label-active {
    padding: 8px 20px;
    background: #fff;
    color: #ABC270;
    border-radius: 5px;

    @media screen and (max-width: 1400px) and (min-width: 769px) {
      padding: 8px 12px;
    }
  }

  .label-inactive {
    padding: 8px 20px;
    background: rgba(251, 198, 88, 0.1);
    color: #FBC658;
    border-radius: 5px;

    @media screen and (max-width: 1400px) and (min-width: 769px) {
      padding: 8px 12px;
    }
  }

  .label-archived {
    padding: 8px 20px;
    background: rgba(53, 71, 80, 0.2);
    color: #354750;
    border-radius: 5px;

    @media screen and (max-width: 1400px) and (min-width: 769px) {
      padding: 8px 12px;
    }
  }

  .data-list-mobile {

    .list-item {
      background: #fff;
      box-shadow: 5px 10px 12px rgba(206, 206, 206, 0.25);
      border-radius: 5px;
      margin-top: 20px;
      padding: 16px 12px;

      .list-item-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        .item-cell {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .button-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        
        .button {
          height: 40px;
        }
      }
    }

    .table-data {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .button {
        margin-top: 20px;
        height: 35px;
      }
    }
  }

  .add-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background: #F6872D;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    user-select: none;

    .button-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .text {
    @media screen and (max-width: 1400px) and (min-width: 769px) {
      font-size: 13px;
    }
  }
}