.all-countries-modal {
  z-index: 1;
  background: rgba(53, 71, 80, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background: #FFFFFF;
    border-radius: 25px;
    padding: 30px 20px 30px 40px;
    width: 50vw;
    min-height: 50vh;
    max-height: 80vh;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      width: 100%;
      min-height: 100vh;
      border-radius: 0;
      padding: 16px;
    }

    .bars-wrapper {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 20px;
    }

    .button {
      margin-top: 20px;
      width: 100px;
      flex: 0 0 50px;
    }
  }
}