.branding-tab {

  @media screen and (max-width: 768px) {
    background: #fff;
    box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
    border-radius: 5px;
    padding: 16px;
  }

  section {
    margin-bottom: 30px;
  }

  .label {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .logo-preview {
    width: 70px;
  }

  .buttons-wrapper {
    
    .button {
      margin-right: 20px;
    }
  }
}