.country-bar {

  .label {
    display: flex;
    justify-content: space-between;
  }
  
  .bar-wrapper {
    margin-top: 10px;
    border-right: 1px solid #354750;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    overflow: hidden;

    .progress-bar {
      color: #354750;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 14px;
      min-width: fit-content;
      padding: 0 5px;

      &:first-of-type {
        border-radius: 4px 0 0 4px;
      }

      &:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &.bar-working-days {
        background: rgba(3, 117, 180, 1);
        color: #fff;
        position: relative;
      }
  
      &.bar-non-working-days {
        background: rgba(3, 117, 180, 0.5);
        color: #fff;
      }
    }
  }

  .bar-limit-exceeded {
    margin-top: 10px;
    border: 1px solid #FF595E;
    width: 100%;
    height: 32px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    background: #FF595E;
    font-size: 14px;
  }
}