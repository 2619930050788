.snackbar {
  z-index: 10;
  position: fixed;
  bottom: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 1;
  transition: all 0.3s;

  &.snackbar-hidden {
    opacity: 0;
    transition: all 0.3s;
  }
  
  .snackbar-content {
    border-radius: 4px;
    padding: 12px 15px 12px 20px;
    color: #fff;
    box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.5);
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 0 10px;

    &.type-success {
      background: #008B69;
    }
    &.type-warning {
      background: orange;
    }
    &.type-error {
      background: red;
    }

    img {
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
      margin-left: 20px;
      padding: 5px;
      cursor: pointer;
      filter: brightness(0) invert(1);
    }
  }
}