.country-chart {
  height: 120px;
  margin: 0 auto 30px;

  .country-name {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .chart-info {
    margin-top: 5px;
    font-size: 10px;
    display: flex;

    .chart-info-group {
      margin-right: 20px;
      display: flex;
      align-items: center;

      span {
        margin-right: 3px;
      }
  
      .dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
  
        &.working-day-dot {
          background: #0375B4;
        }
  
        &.non-working-day-dot {
          background: rgba(3, 117, 180, 0.5);
        }
      }
    }
  }
}