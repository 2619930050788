.new-project-modal {
  background: rgba(53, 71, 80, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .modal-content {
    margin: 100px 0;
    background: #FFFFFF;
    border-radius: 25px;
    padding: 30px 20px 30px 40px;
    width: 50vw;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      min-height: 100vh;
      border-radius: 0;
      padding: 16px;
    }

    h2 {
      font-size: 24px;
      line-height: 28px;
    }

    .inputs {
      margin-top: 30px;
    }

    .input-wrapper {
      margin-bottom: 20px;
      width: fit-content;
  
      &.buttons-wrapper {
        margin-top: 20px;
        display: flex;
  
        .button {
          margin-right: 20px;
        }
      }
    }
  }
}