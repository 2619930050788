.sortable-header {
  user-select: none;
  padding: 15px 0;
  transition: color 0.3s;

  &:hover {
    color: #242424;
    transition: color 0.3s;
  }

  &.header-selected {
    color: #242424;
  }

  span {
    cursor: pointer;

    .sort-arrow {
      opacity: 0.5;
    }
    
    .sort-arrow:nth-of-type(2) {
      margin-right: 5px;
    }

    .sort-arrow-selected {
      opacity: 1;
      filter: brightness(1) saturate(50%);
    }
  }
}