.non-working-days-component {
  background: #fff;
  box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
  border-radius: 20px;
  height: 180px;
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 10px;
  text-align: center;

  @media screen and (max-width: 768px) {
    height: 140px;
  }

  .color-bar {
    display: block;
    position: absolute;
    top: 0px;
    background: rgba(3, 117, 180, 0.5);
    height: 2px;
    width: 100%;
  }

  h2 {
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
    }
  }
}