.quick-log-component {
  background: #fff;
  box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
  border-radius: 20px;
  padding: 30px;
  height: 100%;

  @media screen and (max-width: 768px) {
    padding: 30px 20px;
  }

  .label {
    margin-top: 14px;
  }

  .latest-trackings {
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    text-align: center;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .pickers-line {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 768px) {
      margin-top: 30px;
      grid-template-columns: 1fr;
    }

    .calendar {
      @media screen and (max-width: 768px) {
        display: flex;
        justify-content: center;
      }
    }

    .inputs {
      
      .input-wrapper {
        margin-bottom: 20px;
      }
    }

    .button {
      margin-top: 10px;
      width: 100%;

      @media screen and (max-width: 768px) {
        width: initial;
      }
    }

    button.button-secondary {
      margin-right: 20px;
    }

  }
}


.latest-tracking {
  background: #FAFCFE;
  border-radius: 5px;
  box-shadow: 5px 10px 12px rgba(206, 206, 206, 0.25);
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: #F6F9FA;
    transition: all 0.3s;
  }

  .text {
    margin: 5px 0;
  }
}

.day-type-select {
  display: flex;
  border-radius: 5px;
  overflow: hidden;

  .day {
    flex: 1;
    font-size: 15px;
    line-height: 14px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgba(53, 71, 80, 0.5);
    background: #fff;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s;
    border: 1px solid rgba(53, 71, 80, 0.5);

    &:first-of-type {
      border-radius: 5px 0 0 5px;
      border-right: 0;
    }

    &:last-of-type {
      border-radius: 0 5px 5px 0;
    }

    &:hover {
      background: #F6F9FA;
      transition: all 0.3s;
    }

    &.working-day-selected {
      background: rgba(3, 117, 180, 1);
      color: #fff;

      &.ripple-effect::after {
        background-color: rgba(3, 117, 180, 0.8);
      }
    }

    &.non-working-day-selected {
      background: rgba(3, 117, 180, 0.5);
      color: #fff;

      &.ripple-effect::after {
        background-color: rgba(3, 117, 180, 0.3);
      }
    }
  }
}


.DayPicker-wrapper {
  background: #FAFCFE;
  box-shadow: 5px 10px 12px rgba(206, 206, 206, 0.25);
  border-radius: 5px;
  outline: none;
  height: 100%;
}

.DayPicker-Months {
  height: 100%;
}

.DayPicker-Caption {
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 16px;
  text-align: center !important;
  color: #354750;

  div {
    font-size: 14px !important;
  }
}

.DayPicker-Month {
  border-collapse: separate !important;
  height: calc(100% - 32px);
}

.DayPicker-Weekday {
  font-weight: 700;
  font-size: 13px !important;
  line-height: 16px;
  color: #354750 !important;
}

.DayPicker-Day {
  outline: none;
  color: #354750;
  font-size: 13px;
  background-color: #FAFCFE !important;
  padding: 0 !important;

  .calendar-cell {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 3px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.3s;

    &:hover {
      background: #EEEEEE;
      transition: background 0.3s;
    }
  }
}

.DayPicker-Day--outside {
  .calendar-cell {
    color: #aaa;
    background-color: initial;
    transition: background 0.3s;

    &:hover {
      background: #EEEEEE;
      transition: background 0.3s;
      cursor: pointer;
    }
  }
}

.DayPicker-Day.DayPicker-Day--today {
  font-weight: 400;
  color: #354750;
}

.DayPicker-NavButton {
  outline: none;
  top: 0.8em !important;
}

.DayPicker-NavButton--prev {
  left: 1.5em !important;
}

.DayPicker-Day--workingDays {
  .calendar-cell {
    color: #fff;
    background: rgba(3, 117, 180, 1);
    transition: background 0.3s;

    &:hover {
      background: rgba(3, 117, 180, 0.8);
      transition: background 0.3s;
    }
  }
}

.DayPicker-Day--nonWorkingDays {
  .calendar-cell {
    color: #fff;
    background: rgba(3, 117, 180, 0.5);
    transition: background 0.3s;

    &:hover {
      background: rgba(3, 117, 180, 0.3);
      transition: background 0.3s;
    }
  }
}

.DayPicker-Day--workingAndNonWorkingDays {
  .calendar-cell {
    color: #fff;
    background: linear-gradient(135deg, rgba(3, 117, 180, 1) 50%, rgba(3, 117, 180, 0.5) 50%);

    &:hover {
      background: linear-gradient(135deg, rgba(3, 117, 180, 0.8) 50%, rgba(3, 117, 180, 0.3) 50%);
    }
  }
}

.DayPicker-Day--workingAndNewDays {
  .calendar-cell {
    color: #fff;
    background: linear-gradient(135deg, rgba(3, 117, 180, 1) 50%, #F6872D 50%);

    &:hover {
      background: linear-gradient(135deg, rgba(3, 117, 180, 1) 50%, #F6872D 50%);
    }
  }
}

.DayPicker-Day--nonWorkingAndNewDays {
  .calendar-cell {
    color: #fff;
    background: linear-gradient(135deg, rgba(3, 117, 180, 0.5) 50%, #F6872D 50%);

    &:hover {
      background: linear-gradient(135deg, rgba(3, 117, 180, 0.5) 50%, #F6872D 50%);
    }
  }
}

.DayPicker-Day--newlyAddedDays {
  .calendar-cell {
    color: #fff;
    background-color: #F6872D;

    &:hover {
      background: #F6872D;
    }
  }
}


.DayPicker {
  width: 100%;
  height: 100%;
}

.DayPicker-Month {
  width: 100%;
}