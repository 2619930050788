.report-page {
  padding: 25px 20px 50px;

  @media screen and (max-width: 768px) {
    padding: 16px 16px 32px;
  }

  header.header-desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @media screen and (max-width: 1400px) {
      display: block;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }

    h1 {
      @media screen and (max-width: 1400px) {
        margin-bottom: 20px;
      }
    }

    .text {
      margin-top: 20px;
    }

    .filters-wrapper {
      display: flex;
      align-items: center;
      background: transparent;

      @media screen and (max-width: 1400px) {
        margin-top: 30px;
        flex-wrap: wrap;
      }

      & > div {
        margin-left: 15px;

        @media screen and (max-width: 1400px) {
          margin-left: 0;
          margin-right: 15px;
          margin-bottom: 10px;
        }
      }
      
      .label {
        min-width: 60px;
        text-align: right;

        @media screen and (max-width: 1400px) {
          min-width: fit-content;
          margin-left: 0;
        }
      }
    }

    .exports-line {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media screen and (max-width: 1400px) {
        justify-content: flex-start;
      }
  
      & > div {
        display: flex;
      }
  
      .button {
        margin-left: 10px;

        @media screen and (max-width: 1400px) {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
  }

  header.header-mobile {
    display: none;

    .title-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 40px;

      .search-bar {
        border-color: #F6872D;
        height: 40px;
      }
    }

    @media screen and (max-width: 768px) {
      display: block;
    }

    .filters-wrapper-mobile {
      margin-top: 20px;

      .label {
        margin-bottom: 10px;
      }

      .filters-list {
        display: flex;
        flex-wrap: wrap;

        & > div {
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }

    .mobile-filters-sticky {
      position: fixed;
      left: 0;
      top: 0;
      background: #fff;
      z-index: 1;
      margin-top: 0px;
      padding-top: 10px;
      padding-left: 16px;
      width: 100%;
      border-radius: 0 0 15px 15px;
      box-shadow: 5px 10px 12px rgba(206, 206, 206, 0.25);
      opacity: 1;
      pointer-events: initial;

      &.invisible {
        opacity: 0;
        pointer-events: none;
      }

      .filters-list {
        display: flex;
        flex-wrap: wrap;

        & > div {
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .desktop-filters-sticky {
    position: fixed;
    left: 265px;
    right: 10px;
    top: 0;
    background: #fff;
    z-index: 1;
    margin-top: 0px;
    padding: 10px 10px 0px;
    border-radius: 0 0 15px 15px;
    box-shadow: 5px 10px 12px rgba(206, 206, 206, 0.25);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    opacity: 1;
    pointer-events: initial;

    @media screen and (max-width: 768px) {
      display: none;
    }

    @media screen and (max-width: 992px) {
      left: 10px;
    }

    @media screen and (max-width: 1400px) {
      justify-content: flex-start;
    }

    &.invisible {
      opacity: 0;
      pointer-events: none;
    }

    .label {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }

    .filters-list {
      display: flex;
      flex-wrap: wrap;

      & > div {
        margin-left: 15px;
        margin-bottom: 10px;

        @media screen and (max-width: 1400px) {
          margin-left: 0;
          margin-right: 15px;
        }
      }
    }
  }

  .content-wrapper {
    background: #fff;
    box-shadow: 3px 0px 4px rgba(206, 206, 206, 0.25);
    border-radius: 20px;
    padding: 30px;
    margin-top: 50px;

    @media screen and (max-width: 768px) {
      display: none;
    }

    header {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .table-headers {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 1fr;

      @media screen and (max-width: 1400px) {
        grid-template-columns: 4fr 4fr 4fr 4fr 4fr 4fr 3fr;
      }
    }
  
    .table-row {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 1fr;
      transition: background 0.3s;

      @media screen and (max-width: 1400px) {
        grid-template-columns: 4fr 4fr 4fr 4fr 4fr 4fr 3fr;
      }
  
      &:hover {
        background: #F3F3F3;
        transition: background 0.3s;
  
        .edit-row-icon {
          display: inline-block !important;
        }
      }
  
      .table-cell {
        padding: 15px 10px 15px 0;
        min-width: 0; 
      
        &.centered {
          display: flex;
          align-items: center;
        }
  
        &.last-cell {
          display: flex;
          justify-content: flex-end;
          align-items: center;
  
          .edit-row-icon {
            display: none;
            margin-right: 20px;
            cursor: pointer;
          }

          .save-row-icon {
            margin-right: 10px;
            cursor: pointer;
          }
  
          .cancel-row-icon {
            margin-right: 20px;
            cursor: pointer;
          }
        
          @media screen and (max-width: 1400px) {
            .edit-row-icon {
              margin-right: 10px;
            }
            .save-row-icon {
              margin-right: 10px;
            }
            .cancel-row-icon {
              margin-right: 10px;
            }
          }
        }

        .calendar-input {
          width: 100%;

          .DayPickerInput {
            width: 100%;
          }

          input {
            margin-top: 0;
            width: 100%;
            height: 38px;
          }
          img {
            top: 8px;
          }
        }
      }

      &.small-vertical-padding {
        .table-cell {
          padding: 5px 10px 5px 0;
        }
      }
    }
    
    .table-data {
      margin-top: 30px;
      display: flex;
      align-items: center;
  
      & > div {
        flex: 1;
      }
      
      .button {
        flex: 0 0 120px;
        height: 35px;
      }
    }
  }

  .data-list-mobile {

    .list-item {
      background: #fff;
      box-shadow: 5px 10px 12px rgba(206, 206, 206, 0.25);
      border-radius: 5px;
      margin-top: 20px;
      padding: 16px 12px 32px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      .item-cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .table-data {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .button {
        margin-top: 20px;
        height: 35px;
      }
    }
  }

  .download-report-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background: #F6872D;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    user-select: none;
    z-index: 9;
  }

  .download-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(196, 196, 196, 0.4);
    z-index: 10;

    .download-button {
      position: absolute;
      right: 32.5px;
      bottom: 105px;
      background: #fff;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      user-select: none;
      z-index: 11;

      &.button-mail {
        bottom: 225px;
      }

      &.button-csv {
        bottom: 165px;
      }

      &.button-pdf {
        bottom: 105px;
      }
    }

    .close-modal-button {
      position: absolute;
      right: 20px;
      bottom: 20px;
      background: #F6872D;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      user-select: none;
      z-index: 11;
    }
  }
  
  .button-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .label {
    margin-top: 5px;
  }

  .label-working {
    padding: 8px 20px;
    background: rgba(3, 117, 180, 1);
    color: #fff;
    border-radius: 5px;

    @media screen and (max-width: 1400px) and (min-width: 769px) {
      padding: 8px;
    }
  }

  .label-non-working {
    padding: 8px 20px;
    background: rgba(3, 117, 180, 0.5);
    color: #fff;
    border-radius: 5px;

    @media screen and (max-width: 1400px) and (min-width: 769px) {
      padding: 8px;
    }
  }

  .text {
    @media screen and (max-width: 1400px) and (min-width: 769px) {
      font-size: 13px;
    }
  }
}